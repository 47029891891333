export const storeKeys = {
  theme: "current_theme",
}

export const localStore = {
  get(key, opts = { parse: false, default: null }) {
    if (!key) return null
    const data = localStorage.getItem(key)
    if (!data) return opts.default || null    
    return opts.parse ? JSON.parse(data) : data
  },
  set(key, value, forceParse = false) {
    const parse = (forceParse || typeof value !== 'string')
    const data = parse ? JSON.stringify(value) : value
    localStorage.setItem(key, data)
  },
  remove(...keys) {
    if (!keys) return
    keys.forEach(key => localStorage.removeItem(key))
  },
}
