import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import { storeKeys } from "./lib/local_storage"


document.documentElement.addEventListener("turbo:load", function(e) {
  const theme = localStorage.getItem(storeKeys.theme)
  if (theme) {
    document.documentElement.setAttribute("data-bs-theme", theme)
  }
})