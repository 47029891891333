import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  connect() {
    new TomSelect(this.element, {
      plugins: {
        'clear_button':{
          'title':'Remover seleção',
        },
      },
      render:{
        option_create: function( data, escape ){
          return '<div class="create">Adicionar <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results: function( data, escape ){
          return '<div class="no-results">Nenhum resultado encontrado</div>';
        },
      }      
    })
  }


}
