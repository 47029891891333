import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"  
import { storeKeys, localStore } from "../../lib/local_storage"

export default class extends Controller {
  static values ={
    data: Array,
    label: String,
    debit: Boolean
  }

  xMaxLabelSize = 17

  connect() {
    console.log(this.dataValue)

    const brl = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    const defaultColor = localStore.get(storeKeys.theme) === "dark" ? '#eee' : '#444' 
    const bgColors = this.dataValue.map(d => d.amount_cents > 0 ? '#009688' : '#ef4444')

    new Chart(this.element, {
      type: 'bar',
      data: {
        datasets: [{
          label: this.labelValue || "",
          data: this.dataValue,
          backgroundColor: bgColors,
          borderWidth: 0,
          maxBarThickness: 40,
          borderRadius: 6,
          borderSkipped: false,
        }]
      },
      options: {        
        responsive: true,
        maintainAspectRatio: true,      
        parsing: {
          xAxisKey: 'ref_at',
          yAxisKey: 'amount_cents'          
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function(value, index, values) {
                return brl.format(value / 100)
              }
            }
          },
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.dataset.data[context.dataIndex].amount
              }
            }
          }
        }          
      }
    })
  }

}
