import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"  

// Connects to data-controller="graphs--cost-centers"
export default class extends Controller {
  static values ={
    data: Object,
    label: String,
    debit: Boolean
  }

  xMaxLabelSize = 17

  connect() {
    const brl = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })


    new Chart(this.element, {
      type: 'bar',
      data: {
        labels: Object.keys(this.dataValue).map(label => {
          return label.length > this.xMaxLabelSize ?  label.substring(0, this.xMaxLabelSize) + '...' : label
        }),
        datasets: [{
          label: this.labelValue || "",
          data: Object.values(this.dataValue),
          backgroundColor: this.debitValue ? '#ef4444' : '#009688',
          borderWidth: 0,
          maxBarThickness: 40,
          borderRadius: 6,
        }]
      },
      options: {   
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,      
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: function(value, index, values) {
                return brl.format(value / 100)
              }
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const value = context.dataset.data[context.dataIndex] / 100
                return brl.format(value)
              }
            }
          }
        }
      }
    })
  }



}
