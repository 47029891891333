import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    type: String,
  }

  masks = {
    "phone": {
      mask: "(00) 00000-0000"
    },
    "whatsapp": {
      mask: "+55 (00) 00000-0000"
    },
    "cnpj": {
      mask: "00.000.000/0000-00"
    },
    "cpf": {
      mask: "000.000.000-00"
    },
    "cep": {
      mask: "00000-000"
    },
    "money": {
      mask: "000.000.000.000.000,00",
      options: { reverse: true }
    }
  }

  typeValueChanged() {
    this.setMask()
  }

  setMask() {
    const config = this.masks[this.typeValue]
    if (!config) {
      $(this.element).unmask()
      return
    }

    $(this.element).mask(config.mask, config.options)
  }

}
