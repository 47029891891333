import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table"
export default class extends Controller {
  static targets = ["row", "header"]

  connect() {
    this.headers = Array.from(this.headerTarget.querySelectorAll("th"))
      .map(header => header.innerText)
    this.headerTarget.classList.add("d-none", "d-lg-table-row")
    this.buildMobileRows()
  }

  buildMobileRows() {
    this.rowTargets.forEach(row => {
      const cells = Array.from(row.querySelectorAll("td"))
      if (cells.length > this.headers.length ) return

      const mobileCell = document.createElement("td")
      mobileCell.classList.add("d-table-cell", "d-lg-none", "p-0")

      cells.forEach((cell, index) => {
        cell.classList.add("d-none", "d-lg-table-cell")
        const title = this.getTitle(index)        
        const isTitle = cell.dataset.role === "title"
        const dataDiv = isTitle? this.createDataTitle(title, cell) : this.createDataDiv(title, cell)
        mobileCell.appendChild(dataDiv)
      })
      
      row.appendChild(mobileCell)
    })
  }

  createDataTitle(title, cell) {
    const div = document.createElement("div")
    div.classList.add("d-flex", "justify-content-between", "w-100", "py-2", "bg-body-tertiary", "px-2")
    const html = `
      <span class="fw-semibold fs-sm">${cell.innerHTML}</span> 
    `
    div.insertAdjacentHTML("beforeend", html)
    return div
  }

  createDataDiv(title, cell) {
    const div = document.createElement("div")
    div.classList.add("d-flex", "justify-content-between", "w-100", "p-2", "border-bottom", "fs-sm", "gap-2")
    const html = `${title} ${cell.innerHTML}`
    div.insertAdjacentHTML("beforeend", html)
    return div
  }

  getTitle(index) {
    const header = this.headers[index]
    if (header === "") return "<div></div>"
    const title = header.length === 0 ? "" : `${header}:`        
    const spanText = `
      <span class="text-muted fw-semibold">${title}</span>
    `
    return spanText
  }

}
