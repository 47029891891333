import { Controller } from "@hotwired/stimulus"
import { localStore, storeKeys } from "../lib/local_storage"
import { put } from '@rails/request.js'

// Connects to data-controller="theme-toggler"
export default class extends Controller {
  static values = {
    url: String
  }

  static targets = [ "icon" ]

  iconsAvailable = {
    "dark": "sun",
    "light": "moon-stars"
  }

  connect() {
  }

  async submit(e) {
    e.preventDefault()
    const response = await put(this.urlValue)
    if (response.ok) {
      const { theme } = await response.json
      document.documentElement.setAttribute("data-bs-theme", theme)
      this.iconTarget.className = `bi bi-${this.iconsAvailable[theme]}`
      localStore.set(storeKeys.theme, theme)
    }
  }

}
