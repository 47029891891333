import { Controller } from "@hotwired/stimulus"
import { localStore, storeKeys } from "../lib/local_storage"

export default class extends Controller {
  static values = {
    siteKey: String
  }

  connect() {
    this.button.disabled = true
    this.id = turnstile.render(this.element, {
      sitekey: this.siteKeyValue,
      callback: () => this.button.disabled = false,
      theme: localStore.get(storeKeys.theme) === "dark" ? "dark" : "light",
      language: "pt-BR",
    })
  }

  disconnect() {
    turnstile.remove(this.id)
  }

  get form() {
    return this.element.closest("form")
  }

  get button() {
    return this.form.querySelector("input[type=submit]")
  }
}