import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="year-cost-form"
export default class extends Controller {
  static targets = [ "costCenterField", "yearField" ] 

  connect() {
  }


  handleYearClick = (e) => {
    this.yearFieldTarget.value = e.currentTarget.dataset.year
    this.element.submit()
  }

}
