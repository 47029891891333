import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="transaction-form"
export default class extends Controller {
  static targets = [ 
    "clientSelect", 
    "propertySelect", 
    "bankAccountSelect",
    "submitSpinner",
  ]
  
  connect() {
    if (this.clientSelectTarget.value) {
      this.selectClient()
    }
  }

  async selectClient(event) {
    const clientId = this.clientSelectTarget.value
    if (!clientId) {
      this.disableClientSelects()
      return
    }
    const url = `/clients/${clientId}/relatives?property_select=${this.propertySelectTarget.id}&bank_account_select=${this.bankAccountSelectTarget.id}`
    get(url, {
      responseKind: "turbo-stream"
    })
    this.enableClientSelects()
  }

  disableClientSelects() {
    this.propertySelectTarget.options.length = 0
    this.bankAccountSelectTarget.options.length = 0
    this.propertySelectTarget.disabled = true
    this.bankAccountSelectTarget.disabled = true
  }

  enableClientSelects() {
    this.propertySelectTarget.disabled = false
    this.bankAccountSelectTarget.disabled = false
  }

  submitStart(event) {
    this.submitSpinnerTarget.classList.remove("d-none")
  }

  submitEnd(event) {
    this.submitSpinnerTarget.classList.add("d-none")
  }
}
