import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-form"
export default class extends Controller {
  static targets = [ "kind", "value", "submit" ]


  submitStart(event) {
    this.submitTarget.disabled = true
  }

  submitEnd(event) {
    this.submitTarget.disabled = false
    this.element.reset()
  }

  changeKind(event) {
    this.valueTarget.value = ""
    this.valueTarget.dataset.inputMaskTypeValue = this.kindTarget.value
  }
}
