import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
// Connects to data-controller="tooltip"
export default class extends Controller {
  connect() {
    new bootstrap.Tooltip(this.element, {
      title: this.element.title,
    })
  }
}
